import React from 'react'
import  {Image} from 'react-bootstrap'
import './about.css'
const About123 = () => {
  return (
<section>
{/* <div className='f  '> 

    
<div className='i'>
  <div className='j'>
    <h1>About us</h1>
  </div></div></div>
  <div className=''> 
  <div className=''>
  
   
   


 

 

  </div></div> */}
  <section class="hero flex background11 items-center justify-center py-32">
    <div class="container mx-auto text-center">
      <h2 class="text-6xl font-bold mb-6">Welcome to Addis Hiwot Real Estate Development and Trading Pvt.</h2>
      <p class="text-2xl font-bold mb-6">Find your dream house today!</p>
      {/* <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-4 px-8 rounded shadow">
        Get Started</button> */}
    </div>
  </section>
  {/* <section>
  <div class="individual-feature bg-gray-200 py-20">
    <div class="container mx-auto flex flex-col md:flex-row items-center">
      <div class="md:w-1/2">
        <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRL6vEcOfMuxqWOZbJsiqZgdzh6DFZHIamtMAT8J2qo&s"
      alt="Finance"
      class="w-full  rounded-lg"
    />
      </div>
      <div class="md:w-1/2 md:ml-8 mt-8 md:mt-0">
        <h3 class="text-3xl font-bold mb-4">Effortless Property Search</h3>
        <p class="text-gray-600 mb-6">Addis Hiwot Real Estate Development and Trading Pvt. Limited Company is a sister Company of Eric Eriksson Business Plc. that has 17 years’ work experience in the real estate development industries since December 2005. This company has started its primary investment tasks in Adama Town in developing real estate. With this experience, the company is recently upgraded itself to diversify its objectives to serve the basic necessity of community in alleviating housing problems found in Addis Ababa, and its surrounding communities of Addis Ababa.
This Company is, therefore, established under the name Addis Hiwot Real Estate development and Trading Plc. with registration capital of ETB 5,000,000.00 (Five Million ETB) in Addis Ababa, Ethiopia, in accordance with the commercial code of Ethiopia. Currently, it starts cooperatively with the enterprise and Government Organizations in mounting real estate business activities and other related works, On top of these; it plans to take part in general trading activities throughout the country. </p>
        <a href="#" class="text-blue-500 font-bold">Explore Properties</a>
      </div>
    </div>
  </div>
  </section> */}
  {/* <section className='n12 min-height-500% bg-grey-500 '>
    
    <div className=' n13  '> 
      <h1>addis hiwot realstate overview </h1><br/>
      <h6 className=' '>Addis Hiwot Real Estate Development and Trading Pvt. Limited Company is a sister Company of Eric Eriksson Business Plc. that has 17 years’ work experience in the real estate development industries since December 2005. This company has started its primary investment tasks in Adama Town in developing real estate. With this experience, the company is recently upgraded itself to diversify its objectives to serve the basic necessity of community in alleviating housing problems found in Addis Ababa, and its surrounding communities of Addis Ababa.
This Company is, therefore, established under the name Addis Hiwot Real Estate development and Trading Plc. with registration capital of ETB 5,000,000.00 (Five Million ETB) in Addis Ababa, Ethiopia, in accordance with the commercial code of Ethiopia. Currently, it starts cooperatively with the enterprise and Government Organizations in mounting real estate business activities and other related works, On top of these; it plans to take part in general trading activities throughout the country. 
</h6>
    </div>
  </section><br/> */}
 
    <div class="individual-feature bg-gray-100 py-20">
    <div class="container mx-auto flex flex-col md:flex-row items-center">
      <div class="md:w-1/2">
        <img
        //  src="https://source.unsplash.com/featured/1280x720/?property"
          alt="" 
          class="w-full rounded-lg"/>
        <img
      src="https://www.nuveen.com/global/global/-/media/nuveen/thinking/real-estate/outlook-2023/334100-real-estate-outlook-hero-1380x800px_.ashx?sc_lang=en"
      alt="Finance"
      class="w-full  rounded-lg"
    />
      </div>
      <div class="md:w-1/2 md:ml-8 mt-8 md:mt-0">
        {/* <h3 class="text-3xl font-bold mb-4">Mission and Vission</h3>
        <p className='font-bold'>Vision</p> */}
        <h4 class=" "> Addis Hiwot Real Estate Development and Trading Pvt. Limited Company is a sister Company of Eric Eriksson Business Plc. that has 17 years’ work experience in the real estate development industries since December 2005. This company has started its primary investment tasks in Adama Town in developing real estate. With this experience, the company is recently upgraded itself to diversify its objectives to serve the basic necessity of community in alleviating housing problems found in Addis Ababa, and its surrounding communities of Addis Ababa.
 {/* <br/> */}

</h4>
{/* Mission: Touch the lives of the communities thru providing their basic needs; */}
 {/* <h3 className='font-bold'>Mission </h3>

<h3 className="">Touch the lives of the communities thru providing their basic needs;</h3>
 <h3 className='text-3xl font-bold mb-4'>CORE VALUES</h3>

 <h4>Customer Service</h4> 
 <h5> Ethics </h5> */}
  {/* <h5>Excellence</h5>
  <h5>Innovation</h5>
 <h5> Sustainability</h5> */}
 
     
      </div>
      {/* <h3 className='text-3xl font-bold mb-4'>CORE VALUES</h3>

<h4>Customer Service</h4> 
<h5> Ethics </h5>
 <h5>Excellence</h5>
 <h5>Innovation</h5>
<h5> Sustainability</h5> */}

    </div>

  </div>
  <section>

  <div class="individual-feature bg-gray-100 py-20">
    <div class="container mx-auto flex flex-col-reverse md:flex-row items-center">
      <div class="md:w-1/2 md:mr-8 mt-8 md:mt-0">
        <h3 class="text-3xl font-bold mb-4">Expert Real Estate Agents</h3>
        <p class="text-gray-600 mb-6">Our experienced agents are dedicated to helping you in every step of your property journey.</p>
        {/* <a href="#" class="text-blue-500 font-bold">Meet Our Agents</a> */}
      </div>
      <div class="md:w-1/2">
        <img src="https://img.freepik.com/free-photo/hand-holding-house-real-estate-property-model_53876-129010.jpg?w=826&t=st=1705228872~exp=1705229472~hmac=d675aae6975718efa94933df59eefae770121b3d8112bf89ac5649f76d6bdea6"/>
        <img
      // src="https://cdn.pixabay.com/photo/2016/11/18/17/46/house-1836070_1280.jpg"
      alt=""
      class="w-full rounded-lg"
    />
      </div>
    </div>
  </div>
  </section>
  <section class="features bg-white-100 py-20">
    <div class="container mx-auto">
      <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
        <div class="feature-card text-center px-6 py-10 bg-white shadow-lg">
          <i class="fas fa-home fa-3x mb-6 text-blue-500"></i>
          <h3 class="text-xl font-bold mb-4">Property Listings</h3>
          <p class="text-gray-600">Browse through our extensive collection of property listings.</p>
          {/* <a href="#" class="text-blue-500 font-bold mt-6">Learn More</a> */}
        </div>
        <div class="feature-card text-center px-6 py-10 bg-white shadow-lg">
          <i class="fas fa-key fa-3x mb-6 text-blue-500"></i>
          <h3 class="text-xl font-bold mb-4">Buying & Selling</h3>
          <p class="text-gray-600">We assist you in buying or selling your property.</p>
          {/* <a href="#" class="text-blue-500 font-bold mt-6">Learn More</a> */}
        </div>
        <div class="feature-card text-center px-6 py-10 bg-white shadow-lg">
          <i class="fas fa-money-bill-wave fa-3x mb-6 text-blue-500"></i>
          <h3 class="text-xl font-bold mb-4">Finance </h3>
          <p class="text-gray-600">Get help with property finance  options.</p>
          {/* <a href="#" class="text-blue-500 font-bold mt-6">Learn More</a> */}
        </div>
      </div>
    </div>
  </section>

  <div className='n13'>
    <h1 className='text-black-400 '>Services</h1>
        <div className="row  row-cols-1 row-cols-md-2 g-4">
          <div className="col">
            <div className="card">
              <img
                src="https://images.unsplash.com/photo-1560448204-e02f11c3d0e2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
                className="card-img-top"
                alt="Card 1"
              />
              <div className="card-body">
               
                <p className="card-title">
                Help you find the right property for your needs and budget.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img
                src="https://wallpapers.com/images/hd/real-estate-digital-art-0kmi22tcj2x60lim.jpg"
                className="card-img-top"
                alt="Card 2"
              />
              <div className="card-body">
             
                <p className="card-title">
                Paying applicable tax, utility bills, and others
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img
                src="https://img.freepik.com/free-photo/toy-house-notebook-draft_23-2147831962.jpg"
                className="card-img-top"
                alt=""
              />
              <div className="card-body">
               
                <p className="card-title">
                Security, cleaning, maintenance, and landscaping
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img
                src="https://img.freepik.com/free-photo/villa-house-model-key-drawing-retro-desktop-real-estate-sale-concept_1387-310.jpg?w=2000"
                className="card-img-top"
                alt=""
              />
              <div className="card-body">
                <h5 className="card-title"></h5>
                <h5 className="card-title">
                other related services
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div><br/>
      {/* <div>. */}
  <div class="individual-feature bg-gray-200 py-20">
    <div class="container mx-auto flex flex-col md:flex-row items-center">
      <div class="md:w-1/2">
        <img src="https://img.freepik.com/free-photo/modern-residential-district-with-green-roof-balcony-generated-by-ai_188544-10276.jpg?w=1480&t=st=1705228977~exp=1705229577~hmac=7d7a520c839a3f9a8e344c1853db267151d60e7b704119fe9ee930dff0915427"/>
      </div>
      <div class="md:w-1/2 md:ml-8 mt-8 md:mt-0">
        <h3 class="text-3xl font-bold mb-4">Flexible Financing Options</h3>
        <p class="text-gray-600 mb-6">We offer flexible financing options to
         help you make your dream property a reality.</p>
        {/* <a href="#" class="text-blue-500 font-bold">Discover Financing</a> */}
      </div>
    </div>
  </div> 

  
 <section className=''>
  <div class="faq bagim   py-20">
    <div class="container   mx-auto">
      <h2 class="text-4xl font-bold mb-8 text-center">Frequently Asked Questions</h2>
      <div class="grid grid-cols-2 gap-8">
        <div class="faq-item">
          <h4 class="text-xl font-bold mb-2">Can You Guarantee That You Will Find My Next Home?</h4>
          <p class="text-gray-600">Yes</p>
        </div>
        <div class="faq-item">
          <h4 class="text-xl font-bold mb-2">What Kind Of Properties Do You Sell?</h4>
          <p class="text-gray-600">Residential and commercial Apartments</p>
        </div>
        <div class="faq-item">
          <h4 class="text-xl font-bold mb-2">Can I Be A Part Of The Addis Hiwot Business Family</h4>
          <p class="text-gray-600">ofcourse</p>
        </div>
        <div class="faq-item">
          <h4 class="text-xl font-bold mb-2">Is The Addis Hiwot Business Planning To Open More Offices?</h4>
          <p class="text-gray-600">yes</p>
        </div>
      </div>
    </div>
  </div>
  </section>
  {/* <section class="py-16 bg-gray-100">
    <div class="container mx-auto">
      <h2 class="text-3xl font-bold mb-8">Our agent</h2>
      <div class="flex flex-wrap -mx-4"> */}
      {/* <div class="w-full lg:w-1/4 px-4 mb-6">
          <div class="bg-white rounded shadow p-6 text-center">
            <img src="https://media.glamour.com/photos/5696d70301ed531c6f00b97d/master/w_1600%2Cc_limit/sex-love-life-2015-05-woman-1-main.jpg" alt="Team Member" class="rounded-full h-24 w-24 mx-auto mb-4"/>
            <h3 class="text-xl font-bold mb-2">Misus Yeabsira Abebe</h3>
            <p class="text-gray-700 mb-2">manager</p>
            <div class="flex justify-center">
              <a href="#" class="text-indigo-800 hover:text-indigo-600 mx-2"><i class="fab fa-twitter"></i></a>
              <a href="#" class="text-indigo-800 hover:text-indigo-600 mx-2"><i class="fab fa-facebook"></i></a>
              <a href="#" class="text-indigo-800 hover:text-indigo-600 mx-2"><i class="fab fa-instagram"></i></a>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-1/4 px-4 mb-6">
          <div class="bg-white rounded shadow p-6 text-center">
            <img src="https://th.bing.com/th/id/OIP.ZmE5D8prTUQ2EBqaUB7t5AHaL0?pid=ImgDet&rs=1" alt="Team Member" class="rounded-full h-24 w-24 mx-auto mb-4"/>
            <h3 class="text-xl font-bold mb-2">Ato Gezahegn Dadi</h3>
            <p class="text-gray-700 mb-2">CEO</p>
            <div class="flex justify-center">
              <a href="#" class="text-indigo-800 hover:text-indigo-600 mx-2"><i class="fab fa-twitter"></i></a>
              <a href="#" class="text-indigo-800 hover:text-indigo-600 mx-2"><i class="fab fa-facebook"></i></a>
              <a href="#" class="text-indigo-800 hover:text-indigo-600 mx-2"><i class="fab fa-instagram"></i></a>
            </div>
          </div>
        </div> */}
      
        {/* <div class="w-full lg:w-1/4 px-4 mb-6">
          <div class="bg-white rounded shadow p-6 text-center"> */}
            {/* <img src="https://th.bing.com/th/id/OIP.ZmE5D8prTUQ2EBqaUB7t5AHaL0?pid=ImgDet&rs=1" alt="Team Member" class="rounded-full h-24 w-24 mx-auto mb-4"/> */}
            {/* <h3 class="text-xl font-bold mb-2">ato</h3>
            <p class="text-gray-700 mb-2">managers</p>
            <div class="flex justify-center">
              <a href="#" class="text-indigo-800 hover:text-indigo-600 mx-2"><i class="fab fa-twitter"></i></a>
              <a href="#" class="text-indigo-800 hover:text-indigo-600 mx-2"><i class="fab fa-facebook"></i></a>
              <a href="#" class="text-indigo-800 hover:text-indigo-600 mx-2"><i class="fab fa-instagram"></i></a>
            </div>
          </div>
        </div> */}
        {/* <div class="w-full lg:w-1/4 px-4 mb-6">
          <div class="bg-white rounded shadow p-6 text-center"> */}
            {/* <img src="https://th.bing.com/th/id/OIP.ZmE5D8prTUQ2EBqaUB7t5AHaL0?pid=ImgDet&rs=1" alt="Team Member" class="rounded-full h-24 w-24 mx-auto mb-4"/> */}
            {/* <h3 class="text-xl font-bold mb-2">ato</h3>
            <p class="text-gray-700 mb-2">manager</p> */}
            {/* <div class="flex justify-center">
              <a href="#" class="text-indigo-800 hover:text-indigo-600 mx-2"><i class="fab fa-twitter"></i></a>
              <a href="#" class="text-indigo-800 hover:text-indigo-600 mx-2"><i class="fab fa-facebook"></i></a>
              <a href="#" class="text-indigo-800 hover:text-indigo-600 mx-2"><i class="fab fa-instagram"></i></a>
            </div> */}
          {/* </div>
        </div> */}
      {/* </div>
    </div>
  </section> */}
  </section>
  )
}

export default About123;